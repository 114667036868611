
export default {
    path: "/taxonomies",
    name: "Taxonomies",
    meta: {
        authRequired: true,
    },
    component: () => import("./taxonomies.vue")
}

