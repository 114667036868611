
export default {
    path: "/posts",
    name: "Posts",
    meta: {
        authRequired: true,
    },
    component: () => import("./posts.vue")
}

