
export default {
    path: "/posts-taxonomies",
    name: "PostsTaxonomies",
    meta: {
        authRequired: true,
    },
    component: () => import("./posts-taxonomies.vue")
}

